<template>
  <div>
    <div class="col-xl-12 px-0">
      <div
        class="card card-custom card-stretch gutter-b"
        :class="error.active ? 'bordered-card-danger' : 'bordered-card-primary'"
      >
        <div class="card-body">
          <ErrorBox :errors="projectSegmentationErrors" @clear_errors="clearProjectSegmentationErrors">
          </ErrorBox>
          <ErrorBox :errors="projectSegmentErrors" @clear_errors="clearProjectSegmentErrors">
          </ErrorBox>
          <div class="d-flex justify-content-between">
            <div>
              <h6>{{ $t('SETUP.NAV.SEGMENTATION') }}</h6>
              <p class="mb-10">{{ $t('SETUP.SEGMENTATIONS.DESCRIPTION') }}</p>              
            </div>
            <div>
              <a
                href="javascript:void(0)"
                class="btn btn-primary"
                @click="addSegmentation.active ? addSegmentation.active = false : addSegmentation.active = true"
                :disabled="loadingComplete == false"
              >
                {{ $t('SETUP.SEGMENTATIONS.ADD') }}
              </a>
            </div>
          </div>

          <div v-if="loadingComplete && showAddSegmentation" class="d-flex justify-content-start">
            <AddSegmentation
              :projectId="projectInfo.id"
              class="col-xl-4 px-0"
              @closeForm="addSegmentation.active = false"
            >
            </AddSegmentation>
          </div>

          <div v-if="loadingComplete" class="table-responsive">
            <div class="col-xl-4 table-responsive">
              <table
                id="segmentation_table"
                class="table table-head-custom table-vertical center table-hover mt-5"
                style="table-layout:auto"
              >
                <tbody>
                  <template v-for="(segmentation, i) in projectSegmentations">
                    <SegmentationRow
                      :key="i"
                      :segmentation="segmentation"
                      :projectId="projectInfo.id"
                    >
                    </SegmentationRow>
                    <template v-for="(segment) in projectSegments">
                      <SegmentRow
                        :key="i + 'B' + segment.id"
                        v-if="segment.project_segmentation_id == segmentation.id"
                        :segment="segment"
                        :segmentation="segmentation"
                      >
                      </SegmentRow>
                    </template>
                    <AddSegmentRow
                      :key="i + 'Segment-Add'"
                      :segmentation="segmentation"
                    >
                    </AddSegmentRow>
                  </template>
                </tbody>
              </table>
            </div>
          </div>
          <div class="d-flex justify-content-center mx-0" v-if="!loadingComplete && !error.active">
            <b-spinner
              variant="primary"
              label="Spinning"
              class="table-loader my-3"
            >
            </b-spinner>          
          </div>
          <b-alert v-if="error.active" show variant="danger">
            {{ error.message }}
          </b-alert>
        </div>
      </div>
    </div>
  </div>  
</template>

<script>
import { mapGetters } from "vuex";
import {
  POPULATE_PROJECT_SEGMENT_LIST, 
  CLEAR_PROJECT_SEGMENT_ERRORS 
} from "@/core/services/store/project/project_segments.module";
import {
  POPULATE_PROJECT_SEGMENTATION_LIST,
  CLEAR_PROJECT_SEGMENTATION_ERRORS
} from "@/core/services/store/project/project_segmentations.module";

import ErrorBox from "@/view/components/ErrorBox";
import AddSegmentation from "@/view/content/projects/AddSegmentation";
import SegmentationRow from "@/view/content/projects/SegmentationRow";
import SegmentRow from "@/view/content/projects/SegmentRow";
import AddSegmentRow from "@/view/content/projects/AddSegmentRow";


export default {
  name: "Segmentations",

  components: {
    AddSegmentation,
    SegmentationRow,
    SegmentRow,
    AddSegmentRow,
    ErrorBox
  },

  data() {
    return {
      pageLoader: {
        componentsCompleted: 0,
        componentsRequired: 2
      },
      error: {
        active: false,
        message: ""
      },
      addSegmentation: {
        active: false
      }
    };
  },

  mounted() {
    this.fetchSegments();
    this.fetchSegmentations();
  },

  methods: {
    fetchSegments() {
      let payload = {
        projectId: this.projectInfo.id,
        params: {
          activities: 'withCount',
          assignedProjectInvites: 'withCount',
          projectProfiles: 'withCount',
          paginate: false
        }
      };
      this.$store
        .dispatch(POPULATE_PROJECT_SEGMENT_LIST, payload)
        .then(() => {
          this.pageLoader.componentsCompleted++;
        })
        .catch(() => {
          this.error.active = true;
          this.error.message =
            "We're having some issues retrieving the segment list, please check back later or contact the helpdesk";
        });
    },
    fetchSegmentations() {
      let payload = {
        projectId: this.projectInfo.id,
        params: {
          projectSegments: 'with',
          projectProfiles: 'with',
          invitedUsers: 'with',
          paginate: false
        }
      };
      this.$store
        .dispatch(POPULATE_PROJECT_SEGMENTATION_LIST, payload)
        .then(() => {
          this.pageLoader.componentsCompleted++;
        })
        .catch(() => {
          this.error.active = true;
          this.error.message =
            "We're having some issues retrieving the segmentation list, please check back later or contact the helpdesk";
        });
    },
    clearErrors: function() {
      this.error.active = false;
      this.error.message = "";
    },
    clearProjectSegmentationErrors: function() {
      this.$store.dispatch(CLEAR_PROJECT_SEGMENTATION_ERRORS)
    },
    clearProjectSegmentErrors: function() {
      this.$store.dispatch(CLEAR_PROJECT_SEGMENT_ERRORS)
    }
  },

  computed: {
    ...mapGetters([
      "projectInfo",
      "projectSegments",
      "projectSegmentErrors",
      "projectSegmentations",
      "projectSegmentationErrors"
    ]),

    loadingComplete: function() {
      if (
        this.pageLoader.componentsCompleted >=
          this.pageLoader.componentsRequired &&
        this.error.active == false
      ) {
        return true;
      } else {
        return false;
      }
    },

    showAddSegmentation: function() {
      if (this.projectSegmentations.length == 0) {
        return true;
      } else {
        if (this.addSegmentation.active == 1) {
          return true;
        } else {
          return false;
        }
      }
    }
  }
}
</script>
