<template>
  <b-form-group
    id="input-project-segmentation-active-group"
    :label="$t('FORMS.INPUTS.PROJECT_SEGMENTATION_ACTIVE')"
    :label-sr-only="true"
    label-for="project-segmentation-active"
    class="col-xl-6 px-0"
  >
    <b-form-checkbox
      id="project-segmentation-active"
      v-model="model.$model"
      name="project-segmentation-active"
      value="1"
      unchecked-value="0"
      size="lg"
    >
      <p class="pt-1">
        {{ $t('FORMS.INPUTS.PROJECT_SEGMENTATION_ACTIVE') }}
      </p>
    </b-form-checkbox>
  </b-form-group>
</template>

<script>
export default {
  name: "ProjectSegmentationActive",

  props: [
    'model'
  ],
};
</script>
