<template>
  <tr
    v-if="editSegment.segmentId != segment.id"
  >
    <td style="width:30px"></td>
    <td class="px-2 py-3 pl-7 d-table-cell">
      <a
        href="javascript:void(0)"
        class="text-dark-65 text-hover-primary mb-1 font-size-sm"
        @click="editSegment = { active: true, segmentId: segment.id, segmentationId: segmentation.id, input: segment.name }"
      >
        {{ segment.name }}
      </a>
    </td>
    <td class="px-2 py-3 d-table-cell text-right" style="width:70px">
      <a
        v-if="segmentCanBeDeleted(segment)"
        href="javascript:void(0)"
        class="text-danger text-hover-danger font-size-sm hidden-delete"
        @click="deleteSegment(segmentation.id, segment.id)"
      >
        {{ $t('GENERAL.TEXT.DELETE') }}
      </a>
      <a
        v-else
        href="javascript:void(0)"
        class="text-secondary text-hover-secondary font-size-sm hidden-delete"
      >
        {{ $t('GENERAL.TEXT.IN_USE') }}
      </a>
    </td>
  </tr>
  <tr
    v-else-if="editSegment.segmentId == segment.id"
    :key="i + 'B' + segment.id"
    class="form-inline-row"
  >
    <td style="width:30px"></td>
    <td colspan="2" class="px-2 py-3 pl-7 d-table-cell">
      <div>
        <b-form inline v-on:submit.prevent="updateSegment()">
          <b-input
            class="mb-2 mr-sm-2 mb-sm-0 form-control-solid w-50"
            :placeholder="$t('SETUP.SEGMENTATIONS.EDIT_PLACEHOLDER_2')"
            v-model="editSegment.input"
          ></b-input>
          <b-button
            variant="outline-primary"
            class="py-1 pl-3 pr-2 mr-2"
            @click="updateSegment()"
          >
            <i class="fas fa-check font-size-sm"></i>
          </b-button>
          <b-button
            variant="outline-danger"
            class="py-1 pl-3 pr-2"
            @click="resetUpdateSegment()"
          >
            <i class="fas fa-times font-size-sm"></i>
          </b-button>
        </b-form>
      </div>
    </td>
  </tr>
</template>

<script>
import { 
  UPDATE_PROJECT_SEGMENT,
  DELETE_PROJECT_SEGMENT
} from "@/core/services/store/project/project_segments.module";

export default {
  name: "SegmentRow",

  props: ['segment','segmentation'],

  data() {
    return {
      addSegment: {
        active: false,
        segmentationId: null,
        input: null,
      },
      editSegment: {
        active: false,
        segmentId: null,
        segmentationId: null,
        input: null
      },
    };
  },

  methods: {
    segmentCanBeDeleted: function(segment) {
      if(
        segment.assigned_project_invites_count > 0 ||
        segment.project_profiles_count > 0 ||
        segment.activities_count > 0
      ) {
        return false;
      } else {
        return true;
      }
    },
    deleteSegment(segmentationId, segmentId) {
      let payload = {
        segmentationId: segmentationId,
        segmentId: segmentId
      };
      this.$store
        .dispatch(DELETE_PROJECT_SEGMENT, payload)
        .then(() => {})
        .catch(() => {
          this.error.active = true;
          this.error.message =
            "We're having some issues deleting the project segment, please check back later or contact the helpdesk";
        });
    },
    updateSegment() {
      let payload = {
        segmentationId: this.editSegment.segmentationId,
        segmentId: this.editSegment.segmentId,
        data: {
          name: this.editSegment.input,
          key: this.segment.key
        }
      };
      this.$store
        .dispatch(UPDATE_PROJECT_SEGMENT, payload)
        .then(() => {
          this.resetUpdateSegment();
        })
        .catch(() => {
          this.error.active = true;
          this.error.message =
            "We're having some issues updating the project segment, please check back later or contact the helpdesk";
        });
    },
    resetUpdateSegment() {
      this.editSegment = {
        active: false,
        segmentId: null,
        segmentationId: null,
        input: null
      }
    },
  }
}
</script>
