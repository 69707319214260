<template>
  <tr
    v-if="!addSegment.active"
  >
    <td style="width:30px"></td>
    <td colspan="2" class="px-2 py-3 pl-7 d-table-cell">
      <a
        href="javascript:void(0)"
        class="text-dark-65 text-hover-primary mb-1 font-size-sm"
        @click="enableSegmentInput(segmentation.id)"
      >
        <i class="fas fa-plus-circle font-size-sm mr-2"></i>
        {{ $t('SETUP.SEGMENTATIONS.ADD_ANOTHER') }}
      </a>
    </td>
  </tr>
  <tr
    v-else-if="addSegment.active == true && addSegment.segmentationId == segmentation.id"
    class="form-inline-row"
  >
    <td style="width:30px"></td>
    <td colspan="2" class="px-2 py-3 pl-7 d-table-cell">
      <div>
        <b-form inline v-on:submit.prevent="submitSegment(segmentation.id)">
          <b-input
            :ref="segmentation.id + '-segment-input'"
            class="mb-2 mr-sm-2 mb-sm-0 form-control-solid w-50"
            :placeholder="$t('SETUP.SEGMENTATIONS.ADD_PLACEHOLDER')"
            v-model="addSegment.input"
          ></b-input>
          <b-button
            variant="outline-primary"
            class="py-1 pl-3 pr-2 mr-2"
            @click="submitSegment(segmentation.id)"
          >
            <i class="fas fa-check font-size-sm"></i>
          </b-button>
          <b-button
            variant="outline-danger"
            class="py-1 pl-3 pr-2"
            @click="disableSegmentInput()"
          >
            <i class="fas fa-times font-size-sm"></i>
          </b-button>
        </b-form>
      </div>
    </td>
  </tr>
</template>

<script>
import { CREATE_PROJECT_SEGMENT } from "@/core/services/store/project/project_segments.module";

export default {
  name: "AddSegmentRow",

  props: ['segmentation'],

  data() {
    return {
      addSegment: {
        active: false,
        segmentationId: null,
        input: null,
      },
    };
  },

  methods: {
    enableSegmentInput(segmentationId) {
      this.addSegment.active = true;
      this.addSegment.segmentationId = segmentationId;
      this.focusInput(segmentationId);
    },
    submitSegment(segmentationId) {
      let payload = {
        segmentationId: segmentationId,
        data: {
          name: this.addSegment.input
        }
      };
      this.$store
        .dispatch(CREATE_PROJECT_SEGMENT, payload)
        .then(() => {
          this.disableSegmentInput(segmentationId);
        })
    },
    disableSegmentInput(segmentationId = null) {
      this.addSegment.active = false;
      this.addSegment.segmentationId = null;
      this.addSegment.input = null;
      if (segmentationId != null) {
        this.enableSegmentInput(segmentationId);
      }
    },
    focusInput(segmentationId) {
      setTimeout(() => this.$refs[segmentationId + '-segment-input'].focus(), 100);
    }
  }
}
</script>
