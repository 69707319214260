<template>
  <b-form-group
    id="input-client-city-group"
    :label="$t('FORMS.INPUTS.SEGMENTATION_TITLE') + '*'"
    label-for="input-segmentation-title"
    class="w-100 px-0 mb-3"
  >
    <b-form-input
      type="text"
      class="form-control form-control-solid h-auto py-4 px-6"
      id="input-segmentation-title"
      name="input-segmentation-title"
      v-model="model.$model"
      :state="validateState()"
      aria-describedby="feedback-segmentation-title"
      :disabled="submissionStates.submitting === true"
      :placeholder="$t('FORMS.INPUTS.SEGMENTATION_TITLE')"
    ></b-form-input>
    <b-form-invalid-feedback id="feedback-segmentation-title">
      {{ $t('FORMS.ERROR.SEGMENTATION_TITLE') }}
    </b-form-invalid-feedback>
  </b-form-group>
</template>

<script>
export default {
  name: "ProjectSegmentationTitle",

  props: [
    'model',
    'submissionStates'
  ],
  
  methods: {
    validateState() {
      const { $dirty, $error } = this.model;
      return $dirty ? !$error : null;
    }
  }
};
</script>
