<template>
  <div class="mb-10">
    <b-form @submit.prevent="onSubmit" @reset="onReset">

      <InputProjectSegmentationTitle :model="$v.form.name" :submissionStates="submissionStates">
      </InputProjectSegmentationTitle>

      <InputProjectSegmentationActive :model="$v.form.published" :submissionStates="submissionStates">
      </InputProjectSegmentationActive>

      <div class="d-flex justify-content-start px-0">
        <b-button type="submit" variant="primary" class="mr-2">{{ $t('GENERAL.BUTTONS.SAVE') }}</b-button>
        <b-button type="reset" variant="secondary">{{ $t('GENERAL.BUTTONS.CANCEL') }}</b-button>      
      </div>

    </b-form>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { validationMixin } from "vuelidate";
import { maxLength, required } from "vuelidate/lib/validators";

import {
  CLEAR_PROJECT_SEGMENTATION_ERRORS,
  CREATE_SEGMENTATION
} from "@/core/services/store/project/project_segmentations.module";

import InputProjectSegmentationTitle from "@/view/components/form-inputs/ProjectSegmentationTitle";
import InputProjectSegmentationActive from "@/view/components/form-inputs/ProjectSegmentationActive";

export default {
  mixins: [validationMixin],

  name: "AddSegmentation",

  props: [ "projectId" ],

  components: { InputProjectSegmentationTitle, InputProjectSegmentationActive },

  data() {
    return {
      submissionStates: {
        submitting: false
      },
      form: {
        published: 1,
        name: null,
      }
    };
  },

  validations: {
    form: {
      published: {},
      name: {
        required,
        maxLength: maxLength(255)
      },
    }
  },

  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    clearErrors: function() {
      this.$store.dispatch(CLEAR_PROJECT_SEGMENTATION_ERRORS);
    },
    onSubmit() {
      this.clearErrors();

      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }

      this.submissionStates.submitting = true;

      let payload = {
        projectId: this.projectId,
        data: this.form
      };

      this.$store
        .dispatch(CREATE_SEGMENTATION, payload)
        .then(() => {
          this.$v.form.$reset();
          this.resetForm();
          this.$emit('closeForm');
        })
        .finally(() => {
          this.submissionStates.submitting = false;
        });
    },
    onReset() {
      this.clearErrors();
      this.resetForm();
      this.$emit('closeForm');
    },
    resetForm() {
      this.form = {
        name: null
      };
    }
  },

  computed: {
    ...mapGetters([
      "projectSegmentationErrors"
    ]),
  }
};
</script>
