<template>
  <tr v-if="editSegmentation.segmentationId != segmentation.id">
    <td
      class="px-2 py-3 d-table-cell"
      style="width:30px; cursor:pointer"
      v-b-tooltip.hover
      :title="publishedStatus(segmentation)['text']"
      @click="togglePublished(segmentation)"
    >
      <span
        class="label label-dot mr-2"
        :class="publishedStatus(segmentation)['class']"
      >
      </span>
    </td>
    <td class="px-2 py-3 d-table-cell">
      <a
        href="javascript:void(0)"
        class="text-dark-65 font-weight-bolder text-hover-primary mb-1 font-size-sm"
        @click="editSegmentation = { active: true, segmentationId: segmentation.id, input: segmentation.name }"
      >
        {{ segmentation.name }}
      </a>
    </td>
    <td class="px-2 py-3 d-table-cell text-right" style="width:70px">
      <a
        v-if="canBeDeleted"
        href="javascript:void(0)"
        class="text-danger text-hover-danger font-size-sm hidden-delete"
        @click="deleteSegmentation(segmentation.id)"
      >
        {{ $t('GENERAL.TEXT.DELETE') }}
      </a>
      <a
        v-else
        href="javascript:void(0)"
        class="text-secondary text-hover-secondary font-size-sm hidden-delete"
      >
        {{ $t('GENERAL.TEXT.IN_USE') }}
      </a>
    </td>
  </tr>
  <tr
    v-else
    :key="i"
    class="form-inline-row"
  >
    <td colspan="3" class="px-2 py-3 pl-7 d-table-cell">
      <div>
        <b-form inline v-on:submit.prevent="updateSegmentation()">
          <b-input
            class="mb-2 mr-sm-2 mb-sm-0 form-control-solid w-50"
            :placeholder="$t('SETUP.SEGMENTATIONS.EDIT_PLACEHOLDER')"
            v-model="editSegmentation.input"
          ></b-input>
          <b-button
            variant="outline-primary"
            class="py-1 pl-3 pr-2 mr-2"
            @click="updateSegmentation()"
          >
            <i class="fas fa-check font-size-sm"></i>
          </b-button>
          <b-button
            variant="outline-danger"
            class="py-1 pl-3 pr-2"
            @click="editSegmentation = { active: false, segmentationId: null, input: null }"
          >
            <i class="fas fa-times font-size-sm"></i>
          </b-button>
        </b-form>
      </div>
    </td>
  </tr>
</template>

<script>
import {
  UPDATE_PROJECT_SEGMENTATION,
  DELETE_PROJECT_SEGMENTATION
} from "@/core/services/store/project/project_segmentations.module";

export default {
  name: "SegmentationRow",

  props: ['segmentation', 'projectId'],

  data() {
    return {
      editSegmentation: {
        active: false,
        segmentationId: null,
        input: null
      }
    }
  },

  methods: {
    updateSegmentation() {
      let payload = {
        segmentationId: this.editSegmentation.segmentationId,
        projectId: this.projectId,
        data: {
          name: this.editSegmentation.input,
          key: this.segmentation.key
        }
      };
      this.$store
        .dispatch(UPDATE_PROJECT_SEGMENTATION, payload)
        .then(() => {
          this.resetUpdateSegmentation();
        })
        .catch(() => {
          this.error.active = true;
          this.error.message =
            "We're having some issues updating the project segmentation, please check back later or contact the helpdesk";
        })
    },
    deleteSegmentation(segmentationId) {
      let payload = {
        projectId: this.projectId,
        segmentationId: segmentationId,
      };
      this.$store
        .dispatch(DELETE_PROJECT_SEGMENTATION, payload)
        .then(() => {})
        .catch(() => {
          this.error.active = true;
          this.error.message =
            "We're having some issues deleting the project segmentation, please check back later or contact the helpdesk";
        });
    },
    togglePublished(segmentation) {
      let newPublishedStatus = segmentation.published ? 0 : 1;
      let payload = {
        segmentationId: segmentation.id,
        projectId: this.projectId,
        data: {
          published: newPublishedStatus
        }
      };
      this.$store
        .dispatch(UPDATE_PROJECT_SEGMENTATION, payload)
        .then(() => {})
        .catch(() => {
          this.error.active = true;
          this.error.message =
            "We're having some issues updating the project segmentation, please check back later or contact the helpdesk";
        })
    },
    resetUpdateSegmentation() {
      this.editSegmentation = {
        active: false,
        segmentationId: null,
        input: null
      }
    },
    publishedStatus(segmentation) {
      let returnArray = [];
      if (segmentation.published == 1) {
        returnArray['class'] = "label-success";
        returnArray['text'] = `${this.$t("SETUP.SEGMENTATIONS.PUBLISHED")}`;
      } else {
        returnArray['class'] = "label-danger";
        returnArray['text'] = `${this.$t("SETUP.SEGMENTATIONS.UNPUBLISHED")}`;
      }

      return returnArray;
    },
  },

  computed: {
      canBeDeleted: function() {
        if ( 
            this.segmentation.project_segments != undefined &&
            this.segmentation.project_profiles != undefined &&
            this.segmentation.invited_users != undefined
        ) {
          if (
            this.segmentation.project_segments.length == 0 &&
            this.segmentation.project_profiles.length == 0 &&
            this.segmentation.invited_users.length == 0
          ) {
            return true;
          } else {
            return false;
          }
        } else {
          return true;
        }
      }
  }
}
</script>
